import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import "./newRange.css";
import {
  addRange,
  getRange,
  removeAsset,
  updateRange,
  uploadAsset,
} from "../../redux/apiRequests";
import { useDispatch, useSelector } from "react-redux";
import { Container, Input, Text, Row, Spacer, Button } from "@nextui-org/react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import FileUpload from "../../components/fileUpload/FileUpload";
import { resetCurrentRange, resetRange } from "../../redux/rangeRedux";
/* import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"; */
import app from "../../firebase";

export default function NewCategory() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const history = useHistory();
  const dispatch = useDispatch();
  const range = useSelector((state) => state.range.range);
  const [files, setFiles] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("fr");
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    name: {
      text: "",
      color: "",
    },
    image: {
      text: "",
      color: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const isDuplicate = useSelector((state) => state.range.isDuplicate);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePond, setFilePond] = useState(null);
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    dispatch(resetRange());
    dispatch(resetCurrentRange());
  }, []);

  useEffect(() => {
    getRange(id, dispatch);
  }, [id]);

  useEffect(() => {
    id &&
      range &&
      setFiles([
        {
          source: `https://laboratoireluxecosmetique.com/assets/products/${range.image}`,
          options: {
            type: "local",
          },
        },
      ]);
  }, [range]);

  useEffect(() => {
    if (isDuplicate) {
      toast.error("La gamme que vous essayer d'ajouter existe déjà !", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      });
    }
  }, [isDuplicate]);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const handleLanguageChange = (e) => {
    setCurrentLanguage(e.target.value);
  };

  const resetHelpers = (e) => {
    e.target.name === "name" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "",
            color: "",
          },
        };
      });

    e.target.name === "image" &&
      setHelper((prev) => {
        return {
          ...prev,
          image: {
            text: "",
            color: "",
          },
        };
      });
  };

  const handleClick = async (e) => {
    e.preventDefault();

    inputs.name &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "",
            color: "",
          },
        };
      });

    inputs.name_en &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "",
            color: "",
          },
        };
      });

    !range &&
      !inputs.name &&
      currentLanguage === "en" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "La version en français est manquante. Veuillez changer de langue en cliquant sur la sélection en haut à droite",
            color: "error",
          },
        };
      });

    !range &&
      !inputs.name_en &&
      currentLanguage === "fr" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "La version anglaise est manquante. Veuillez changer de langue en cliquant sur la sélection en haut à droite",
            color: "error",
          },
        };
      });

    !range &&
      !inputs.name_en &&
      currentLanguage === "en" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "Le nom (anglais) de la nouvelle gamme ne peut être vide",
            color: "error",
          },
        };
      });

    !range &&
      !inputs.name &&
      currentLanguage === "fr" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "Le nom de la nouvelle gamme ne peut être vide",
            color: "error",
          },
        };
      });

    if (!range && inputs.name && inputs.name_en) {
      dispatch(resetRange());
      setLoading(true);
      filePond &&
        filePond.getFiles().forEach(async (file) => {
          if (file.status !== 5) {
            setHelper((prev) => {
              return {
                ...prev,
                image: {
                  text: "L'image de couverture ne s'est pas chargée correctement. Veuillez réessayer",
                  color: "error",
                },
              };
            });
          } else {
            return;
          }
        });

      var reduced = filePond?.getFiles().reduce(function (filtered, file) {
        if (file.status === 5) {
          filtered.push(file.filename);
        }
        return filtered;
      }, []);

      reduced && (await uploadAsset(reduced[0], dispatch));

      const slug = inputs.name_en.toLowerCase().replace(/ /g, "-");
      addRange(
        reduced
          ? {
            slug: slug,
            name: inputs.name,
            en: { name: inputs.name_en },
            image: reduced[0],
            updatedBy: user._id,
          }
          : {
            slug: slug,
            name: inputs.name,
            en: { name: inputs.name_en },
            updatedBy: user._id,
          },
        dispatch
      )
        .then(() => {
          setLoading(false);
          if (!isDuplicate) {
            toast.success("La gamme a été bien ajoutée", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              transition: Flip,
            });
            dispatch(resetCurrentRange());
            setTimeout(() => {
              history.push("/ranges");
            }, 2000);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (range && (inputs.name || inputs.name_en ||
      filePond?.getFiles().reduce(function (filtered, file) {
        if (file.status === 5) {
          filtered.push(file.filename);
        }
        return filtered;
      }, []))) {
      dispatch(resetRange());
      setLoading(true);
      filePond &&
        filePond.getFiles().forEach(async (file) => {
          if (range && file.filename !== range.image && file.status !== 5) {
            setHelper((prev) => {
              return {
                ...prev,
                image: {
                  text: "L'image de couverture ne s'est pas chargée correctement. Veuillez réessayer",
                  color: "error",
                },
              };
            });
          } else {
            return;
          }
        });

      var reduced = filePond?.getFiles().reduce(function (filtered, file) {
        if (file.status === 5 && range && file.filename !== range.image) {
          filtered.push(file.filename);
        }
        return filtered;
      }, []);

      filePond?.getFiles().length > 0 && reduced.length > 0 && (await removeAsset(range.image, dispatch));

      reduced && (await uploadAsset(reduced[0], dispatch));

      const slug = inputs.name_en
        ? inputs.name_en.toLowerCase().replace(/ /g, "-")
        : range.slug;
      updateRange(
        range._id,
        reduced
          ? {
            slug: slug,
            name: inputs.name ? inputs.name : range.name,
            en: { name: inputs.name_en ? inputs.name_en : range.en.name },
            image: reduced[0],
            updatedBy: user._id,
          }
          : {
            slug: slug,
            name: inputs.name ? inputs.name : range.name,
            en: { name: inputs.name_en ? inputs.name_en : range.en.name },
            updatedBy: user._id,
          },
        dispatch
      )
        .then(() => {
          setLoading(false);
          if (!isDuplicate) {
            toast.success("La gamme a été bien mise à jour", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              transition: Flip,
            });
            dispatch(resetCurrentRange());
            setTimeout(() => {
              history.push("/ranges");
            }, 2000);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container className="newRange">
      <Spacer y={1} />
      <Row align="center" justify="space-between">
        <Text>
          <Text h1>
            {range ? "Modifier la " : "Nouvelle "}
            gamme
          </Text>
          <Text h3>{currentLanguage === "en" && "(version anglaise)"}</Text>
        </Text>
        <div className="select-container-language">
          <div className="select-wrapper">
            <select
              className="next-ui-select"
              name="language"
              onChange={handleLanguageChange}
            >
              <option value="fr">Français</option>
              <option value="en">Anglais</option>
            </select>
          </div>
        </div>
      </Row>
      <Row>
        <form className="addRangeForm">
          {currentLanguage === "fr" && (
            <Input
              label="Nom"
              placeholder="Crêmes"
              size="large"
              width="100%"
              name="name"
              value={inputs.name}
              shadow={false}
              bordered
              initialValue={!id || !range ? null : range?.name}
              color={helper.name.color}
              helperColor={helper.name.color}
              helperText={helper.name.text}
              onChange={handleChange}
            />
          )}
          {currentLanguage === "en" && (
            <Input
              label="Nom"
              placeholder="Creams"
              size="large"
              width="100%"
              name="name_en"
              value={inputs.name_en}
              shadow={false}
              bordered
              initialValue={!id || !range ? null : range?.en?.name}
              color={helper.name.color}
              helperColor={helper.name.color}
              helperText={helper.name.text}
              onChange={handleChange}
            />
          )}
          {currentLanguage === "fr" && <Spacer y={1.5} />}
          {currentLanguage === "fr" && (
            <Row>
              <label>Image de couverture</label>
            </Row>
          )}
          {currentLanguage === "fr" && <Spacer y={0.5} />}
          {currentLanguage === "fr" && (
            <FileUpload
              maxFiles={1}
              setFilePond={setFilePond}
              files={files}
              setFiles={setFiles}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
            />
          )}
          {currentLanguage === "fr" && !helper?.image?.text && (
            <Text h5 style={{ marginTop: "-0.5rem 0 0 10px" }}>
              Seule les images de type webp sont acceptées.
            </Text>
          )}
          {currentLanguage === "fr" && helper?.image?.text && (
            <Text
              style={{
                margin: "-0.5rem 0 0 10px",
                color: "#F21351",
                fontSize: "11.2px",
              }}
            >
              {helper.image.text}
            </Text>
          )}
          <Spacer y={1.5} />
          <Button
            loaderType="spinner"
            color="success"
            {...(loading && { loading })}
            onClick={handleClick}
          >
            {range ? "Modifier" : "Ajouter"}
          </Button>
          <Spacer y={1.5} />
        </form>
      </Row>
      <ToastContainer />
    </Container>
  );
}